<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap bg_color--5 mt--150">
          <v-row>
          <v-col lg="2"
                     md="2"
                     sm="12"
                     cols="12">
                  
              </v-col>
               <v-col lg="2"
                     md="2"
                     sm="12"
                     cols="12">
                  <div class="single-column">
                    <h2>Κατηγορίες Δώρων</h2>
                    <FlashMessage></FlashMessage>
                      <ul class="menu custompozition4"  style="list-style-type: none;">
                        <div v-for="(category, i) in categories"
                              :key="i"> 
                            <li style="padding: 5px;">
                              <p><img :src="`${category.image}`" width="30px" style="display: inline-block;" />
                                <a v-on:click="updateCategory(category.gift_category_id)"> {{ category.name.el }} ({{ category.total_gifts }})
                                </a>
                              </p>
                            </li>
                        </div>
                      </ul>
                  </div>
              </v-col>
               <v-col lg="4"
                     md="4"
                     sm="12"
                     cols="12">
                     <b-table small :fields="fields" :items="gifts" :per-page="perPage"
                  :current-page="currentPage" class="elevation-1 "
                  stripped
                   responsive="sm">
                    <template #cell(title)="data" >
                       <img :src="`${data.item.image}`" width="200px"  class=" mt--10 mb--10 "/>
                        <h2> {{ data.item.title }} </h2>
                        <p class="isRed"> {{ data.item.points }} πόντοι</p>
                        <p> {{ data.item.description }} </p>
                        <div v-if="permission">
                                    <b-button  v-if="data.item.promo_type != 'product'" @click="data.item.promo_type === 'product' ? redeemProduct() : redeemCoupon(data.item.code)" :disabled="data.item.points > user.total_points" class="btn" variant="primary">Εξαργύρωση</b-button>
                                    <p class="isGrey" v-if="data.item.points > user.total_points">Δεν έχεις αρκετούς πόντους για αυτό το δώρο</p>
                                    <template v-if="data.item.promo_type === 'product'">
                                        <v-row justify="space-around">
                                            <v-col cols="auto">
                                            <v-dialog
                                                transition="dialog-top-transition"
                                                max-width="600"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                
                                                <b-button :disabled="data.item.points > user.total_points" 
                                                v-bind="attrs" v-on="on" class="btn" variant="primary">Εξαργύρωση</b-button>

                                                </template>
                                                <template v-slot:default="dialog">
                                                
                                                <v-card>
                                                    <v-toolbar
                                                    color="primary"
                                                    dark
                                                    >Εξαργύρωση</v-toolbar>
                                                      <v-card-text>
                                                        <v-container>
                                                          <v-row>
                                                          <h4>Στοιχεία Διεύθυνσης:</h4>
                                                            <v-col cols="12">
                                                              <v-text-field
                                                                label="Διεύθυνση *"
                                                                v-model="address" 
                                                                :rules="addressRules"
                                                                type="text"
                                                                required
                                                              ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                              <v-text-field
                                                                label="Ταχυδρομικός Κώδικας *"
                                                                v-model="postalCode" 
                                                                :rules="postalCodeRules"
                                                                type="text"
                                                                required
                                                              ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                              <v-text-field
                                                                label="Πόλη *"
                                                                v-model="city" 
                                                                :rules="cityRules"
                                                                type="text"
                                                                required
                                                              ></v-text-field>
                                                            </v-col>
                                                          <h4>Στοιχεία παραλήπτη:</h4>
                                                            <v-col cols="12">
                                                              <v-text-field
                                                                label="Όνομα *"
                                                                v-model="firstName" 
                                                                :rules="firstNameRules"
                                                                type="text"
                                                                required
                                                              ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                              <v-text-field
                                                                label="Επώνυμο *"
                                                                v-model="lastName" 
                                                                :rules="lastNameRules"
                                                                type="text"
                                                                required
                                                              ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                              <v-text-field
                                                                label="Κινητό Τηλέφωνο *"
                                                                v-model="mobile" 
                                                                :rules="mobileRules"
                                                                type="text"
                                                                required
                                                              ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                              <v-text-field
                                                                label="Email *"
                                                                type="email"
                                                                :rules="emailRules"
                                                                v-model="email"
                                                                required
                                                              ></v-text-field>
                                                            </v-col>
                                                          </v-row>
                                                        </v-container>
                                                        <small>*Υποχρεωτικά πεδία</small>
                                                        <p class="error" v-if="errors">{{ errors }}</p>
                                                       </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                  <v-btn
                                                        color="white darken-1"
                                                        text
                                                        @click="dialog = false"
                                                      >
                                                        Κλείσιμο
                                                      </v-btn>
                                                    <v-btn
                                                        text
                                                       @click="data.item.promo_type === 'product' ? redeemProduct(data.item.code) : redeemCoupon(data.item.code)" 
                                                    >Εξαργύρωση</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                                </template>
                                            </v-dialog> 
                                            </v-col>
                                        </v-row>
                                        </template>

                              </div>
                    </template>
                  </b-table>


                 <b-pagination
                   v-if="gifts.length > 5"
                    v-model="currentPage"
                    :total-rows="gifts.length"
                    :per-page="perPage"
                    aria-controls="itemList"
                    align="center"
                  ></b-pagination>
                  
              </v-col>
              

              <v-col lg="2"
                     md="2"
                     sm="12"
                     cols="12">
                  <div class="single-column">
                     <img src="https://elinup.gr/assets/img/metaforika.png" width="200px" />
                  </div>
              </v-col>
          </v-row>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import Gifts from "@/components/gifts/Gifts";
  import GiftCategories from "@/components/gifts/GiftCategories";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import FlashMessage from '@smartweb/vue-flash-message';
  

  export default {
    name: 'categories',
    data() {
        return  {
           fields: [
          // A column that needs custom formatting
          { key: 'title', label: '' }, 
        ],
        perPage: 5,
        currentPage: 1,
          errors: '',
            valid: true,
            dialog: false,
            mobile: '',
            password: '',
            city: '',
            postalCode: '',
            firstName: '',
            lastName: '',
            email: '',
            address: '',
            mobileRules: [
              v => !!v || 'Το κινητό τηλέφωνο είναι υποχρεωτικό',
              v => /^\d+$/.test(v) || 'Το κινητό τηλέφωνο πρέπει να περιέχει μόνο αριθμούς',
              v => (v && (""+v).length == 10) || 'Το κινητό τηλέφωνο πρέπει να αποτελείται απο 10 αριθμούς',
            ],
            passwordRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
            ],
            addressRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
            ],
            postalCodeRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
            ],
            cityRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
            ],
            firstNameRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
            ],
            lastNameRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
            ],
            emailRules: [
              v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
            ],
        }
    },
    props: ['categoriesChosen', 'giftsChosen'],
    components: {
      Header,
      Footer,
      Gifts,
      GiftCategories,
    },
    computed: {
       ...mapGetters (["categories"]),
       ...mapGetters (["gifts"]),
       ...mapGetters (["user"]),
      permission() {
        return this.$store.state.user;
      },

    },
    async  created () {
      await this.getCategories();
      await this.getGifts(8);
      await this.getUser();

    },
    methods: {
      ...mapActions(["getCategories"]),
      ...mapActions(["getGifts"]),
      ...mapActions(["getUser"]),
      rows() {
        return this.getGifts.length
      },

      updateCategory(id){ 
         this.getGifts(id);
      },
      async redeemCoupon(couponCode){
        try{
          console.log(couponCode);
          const response = await axios.post('external/me/transactions/coupon-claim', 
          {
              code: couponCode,
          }).then(response => {
              console.log(response.data)
              // this.$swal('Hello Vue world!!!');
                      // this.$swal('Heading', 'this is a Heading', 'OK');
              this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'success',
                showLoaderOnConfirm: true
              })

          }).catch(error => {
              console.log(error.response.data)

              if (error.response.data.code == 401) {
                  this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
              } 
          });      
        }catch(e){
            this.error = e.message;
            return e;
        }
      },
      async redeemProduct(couponCode){
        try{
          console.log(couponCode);
          const response = await axios.post('external/me/transactions/coupon-claim', 
          {
              code: couponCode,
              shipping_details: {
                  recipient_address: {
                      address: this.address,
                      floor: 3,
                      bell_name: "bell",
                      postal_code: this.postalCode,
                      city: this.city
                  },
                  recipient_information: {
                      first_name: this.firstName,
                      last_name: this.lastName,
                      mobile: this.mobile,
                      email: this.email,
                      email_1: this.email
                  }
            }
          }).then(response => {
              console.log(response.data)
          }).catch(error => {
              console.log(error.response.data)

              if (error.response.data.code == 401) {
                  this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
              } 
          });      
        }catch(e){
            this.error = e.message;
            return e;
        }
      }
    },
  };
</script>

<style scoped>
.isGrey{
  color: #ada7a7;
}
.isRed{
  color: #db3d4c;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}
.table th, .table td {
  border: none;
}
a:hover {
  background: #86aad4;
}
</style>