<template>
        <!-- Start Blog Area  -->
        <v-col lg="4"
                     md="4"
                     sm="4"
                     cols="12">
                  <div class="single-column">
                          <ul class="menu custompozition4"  style="list-style-type: none;">
                    
                        <div v-for="(category, i) in categories"
                              :key="i"> 
                              <li v-on:click="updateCategory(category.gift_category_id)" class="item-507"><img :src="`${category.image}`" width="30px" />{{ category.name.el }} ({{ category.total_gifts }})
                            </li>
                          </div>
                           
                        </ul>
                      
                  </div>
              </v-col>
        <!-- End Blog Area  -->
</template>

<script>

import { mapMutations, mapActions, mapGetters } from "vuex";


    export default {  
        name: 'categories',
        computed: {
            ...mapGetters (["categories"]),
        },
         async  created () {
            await this.getCategories();
        },
        methods: {
            ...mapActions(["getCategories"]),
            updateCategory(id){
                console.log(id);
                 this.$forceUpdate();
            }
        },
    }    


</script>